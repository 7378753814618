import http from '@/api/request'

// 推广员招募配置获取
export function getPromoterConfig () {
  return http().get('/base/api/shop/website/domain')
}

// 申请成为推广员
export function promoterApply (data) {
  return http().post('/marketing/auth/promoter/apply', data)
}

// 推广员申请记录详情
export function promoterApplyDetail () {
  return http().get('/marketing/auth/promoter/apply/record/view')
}

// 校验用户是否满足申请推广员条件
export function promoterApplicationConditions () {
  return http().post('/marketing/auth/promoter/check/apply')
}

// 推广员基础信息详情
export function promoterDetail () {
  return http().get('/marketing/auth/promoter/view')
}

// 推广员账户变动记录分页
export function accountRecordsList (data) {
  return http().post('/marketing/auth/promoter/account/change/record/page', data)
}

// 佣金排行
export function getRankingList () {
  return http().get('/marketing/auth/promoter/account/amount/rank')
}

// 推广员客户分页
export function customerPaginationList (data) {
  return http().post('/marketing/auth/promoter/customer/page', data)
}

// 推广订单分页
export function promoteOrdersList (data) {
  return http().post('/marketing/auth/promotion/order/page', data)
}

// 推广员账户详情
export function accountDetails () {
  return http().get('/marketing/auth/promoter/account/view')
}

// 推广员账户提现申请
export function confirmPayouts (data) {
  return http().post('/marketing/auth/promoter/account/withdrawal/apply', data)
}

// 推广团队邀请列表
export function getInviteList (data) {
  return http().post('/marketing/auth/promoter/page', data)
}

// 推广员等级列表
export function levelsList () {
  return http().post('/marketing/auth/promotion/level/list')
}

// 推广海报列表
export function postersList (data) {
  return http().post('/marketing/auth/promotion/poster/list', data)
}
// 绑定银行卡
export function bindBankCard (data) {
  return http().post('/base/auth/shop/user/bind/bank/card', data)
}

// 校验银行卡
export function checkBankCard (params) {
  return http().get(`https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?cardNo=${params}&cardBinCheck=true`)
}

// 商品分销班级分页
export function getClassList (data) {
  return http().post('/resource/auth/classroom/page', data)
}
